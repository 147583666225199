export const TheoriesNav = {
  id: "1",
  prevLink: "/misc",
  nextLink: "/buffalo",
};

export const BuffaloNav = {
  id: "2",
  prevLink: "/theories",
  nextLink: "/sandys",
};

export const SandysNav = {
  id: "3",
  prevLink: "/buffalo",
  nextLink: "/calendar",
};

export const CalNav = {
  id: "4",
  prevLink: "/sandys",
  nextLink: "/theories",
};
