import React from "react";
import { Container } from "../globalStyles";
import {
  ContentGrid,
  Img,
  Heading,
  Section1,
  Section2,
  Section3,
  Section4,
} from "../components/PageHero/PageHeroStyles";
import {
  GalleryGrid,
  GridImg,
  ImgContainer,
} from "../components/PageGallery/PageGalleryStyles";
import { imgGal } from "../data/SpGalleryData";
import { motion } from "framer-motion";
import ProjectNav from "../components/ProjectNav/ProjectNav";
import { SandysNav } from "../components/ProjectNav/ProjectNavData";

const Sandys = () => {
  const spData = {
    heading: (
      <>
        {" "}
        {"Sandy's"}
        <br />
        {"Petshop"}
      </>
    ),
    img: "./images/sp/sandys1.jpg",
    alt: "case study project image",
    title1: "Role",
    listItems1: [
      "Webdesign",
      "HTML, CSS, JS, PHP & MYSQL",
      "Responsive Layout",
      "Logo Design",
      "Mockups",
      "Brand Identity",
    ],
    title2: "Deliverables",
    listItems2: [
      "Brochure Website",
      "Appointment Database",
      "Logo & Brand Developement",
    ],
    title3: "Client",
    listItems3: ["Case Study"],
    title4: "Overview",
    listItems4:
      "Case study project was part of a responsive web and mobile app certification course at the University at Buffalo. Tasked with creating a responsive website for a fictional pet grooming store using HTML/PHP with a MYSQL database for making pet grooming appointments. The forms were validated using Javascript before being submitted to the database. The appointments could then be accessed through an admin backend. In addition to the design and developement, I created a logo and mockups for the brands identity.",
  };

  return (
    <>
      <Container>
        <ContentGrid>
          <Heading
            initial={{ opacity: 0, y: 20 }}
            transition={{ delay: 0.3, duration: 0.6 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            {spData.heading}
          </Heading>

          <Img
            initial={{ opacity: 0, y: 50 }}
            transition={{ delay: 0.7, duration: 0.6 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            src={spData.img}
            alt={spData.alt}
          />

          <Section1>
            <motion.ul
              initial={{ opacity: 0 }}
              transition={{ delay: 0.7, duration: 0.6 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
            >
              <span>{spData.title1}</span>
              {spData.listItems1.map((li) => (
                <motion.li
                  initial={{ opacity: 0, y: 5 }}
                  transition={{ delay: 1, duration: 0.6 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, margin: "70px" }}
                >
                  {li}
                </motion.li>
              ))}
            </motion.ul>
          </Section1>

          <Section2>
            <motion.ul
              initial={{ opacity: 0 }}
              transition={{ delay: 0.7, duration: 0.6 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
            >
              <span>{spData.title2}</span>
              {spData.listItems2.map((li) => (
                <motion.li
                  initial={{ opacity: 0, y: 5 }}
                  transition={{ delay: 1, duration: 0.6 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, margin: "100px" }}
                >
                  {li}
                </motion.li>
              ))}
            </motion.ul>
          </Section2>
          <Section3>
            <motion.ul
              initial={{ opacity: 0 }}
              transition={{ delay: 0.7, duration: 0.6 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
            >
              <span>{spData.title3}</span>

              {spData.listItems3.map((li) => (
                <motion.li
                  initial={{ opacity: 0, y: 5 }}
                  transition={{ delay: 1, duration: 0.6 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, margin: "100px" }}
                >
                  {li}
                </motion.li>
              ))}
            </motion.ul>
          </Section3>

          <Section4>
            <motion.ul
              initial={{ opacity: 0 }}
              transition={{ delay: 0.7, duration: 0.6 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
            >
              <span>{spData.title4}</span>
              <motion.p
                initial={{ opacity: 0, y: 5 }}
                transition={{ delay: 1, duration: 0.6 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, margin: "70px" }}
              >
                {spData.listItems4}
              </motion.p>
            </motion.ul>
          </Section4>
        </ContentGrid>
        <GalleryGrid>
          {imgGal.map((item) => {
            return (
              <ImgContainer
                gridcolumn={item.gridcolumn}
                gridrow={item.gridrow}
                initial={{ opacity: 0, y: 50 }}
                transition={{ delay: 0.3, duration: 0.6 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
              >
                <GridImg src={item.src} />
              </ImgContainer>
            );
          })}
        </GalleryGrid>

        <ProjectNav {...SandysNav} />
      </Container>
    </>
  );
};
export default Sandys;
