import styled from 'styled-components';

export const ProNavContainer = styled.div `
margin: 100px 2rem;
padding: .5em 0;
/* border-top: 1px solid var(--second-text-color); */
display: flex;
justify-content: center;
list-style: none;
font-size:1rem;

& li a:link, a:visited, a:link  {
    
    color:var(--text-color);
    padding-left: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-right: 2rem;
    align-items: center;
    border: none;
    background:transparent;
    text-decoration: none;
    line-height:1.5rem;
}



`