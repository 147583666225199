import React from "react";

const IconMail = () => {
  return (
    <svg
      fill="red"
      version="1.1"
      width="35px"
      height="25px"
      viewBox="0 0 125 100"
    >
      <path d="M60,0 l50,0 a10,10 0 0,1 7,17 l-50,50 a10,10 0 0,1 -13,0 l-50,-50 a10,10 0 0,1 7,-17z" />
      <path d="M60,90 l54,0 a10,10 0 0,0 7,-7 l0,-60 -50,50 a15,15 0 0,1 -21,0 l-50,-50 0,60 a10,10 0 0,0 7,7z" />
    </svg>
  );
};

export default IconMail;
