import styled from "styled-components";
import { motion } from "framer-motion";

export const DropDownContainer = styled(motion.div)`
  display: flex;
  width: 200px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  box-sizing: border-box;
  position: relative;
  margin-right: 1em;
`;

export const ContactButton = styled(motion.div)`
  width: 40px;
  height: 40px;
  margin-top: 0.5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const DropDown = styled(motion.div)`
  position: absolute;
  top: 10px;
  border-radius: 15px;
  padding: 0.5em;
  background-color: var(--text-color);
  color: var(--background-color);
  /* right: 40px; */
  font-size: calc(1vw + 1em);

  ::after {
    content: "";
    position: absolute;
    right: -8px;
    top: 2px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid var(--text-color);
  }
  a {
    text-decoration: none;
    color: var(--background-color);
  }
`;

export const MailIcon = styled.div`
  display: flex;
  height: 45px;
  width: 45px;

  svg {
    margin-top: 1.2em;
    margin-left: 1em;
    width: 30px;
    height: 20px;
    fill: var(--text-color);
    /* fill: #a2ff00; */
    /* pointer-events: fill; */
  }
`;
