import React from "react";
import { Container } from "../globalStyles";
import {
  ContentGrid,
  Img,
  Heading,
  Section1,
  Section2,
  Section3,
  Section4,
} from "../components/PageHero/PageHeroStyles";
import {
  GalleryGrid,
  Vid,
  GridImg,
  ImgContainer,
} from "../components/PageGallery/PageGalleryStyles";
import { imgGal, vid, vidLG } from "../data/BfloGalleryData";
import { motion } from "framer-motion";
import ProjectNav from "../components/ProjectNav/ProjectNav";
import { BuffaloNav } from "../components/ProjectNav/ProjectNavData";

const Buffalo = () => {
  const bfloData = {
    heading: (
      <>
        {" "}
        {"Buffalo"}
        <br />
        {"Heritage"}
      </>
    ),
    img: "./images/bflo/bflo2.jpg",
    alt: "bflo project image",
    title1: "Role",
    listItems1: [
      "Web Dev / Design",
      "eCommerce",
      "HTML CSS JS PHP",
      "Logo Design",
      "Apparel Design",
    ],
    title2: "Deliverables",
    listItems2: ["eCommerce webstore", "Screen printed shirt", "Mug & Poster"],
    title3: "Client",
    listItems3: ["Buffalo Heritage Design"],
    title4: "Overview",
    listItems4:
      "The Buffalo Heritage design uses geometric abstraction, drawing inspiration from Buffalo, New York’s rich art deco architectural history. I developed an eCommerce storefront to feature the design. The storefront uses custom PHP code on the WordPress/WooCommerce templating platform.",
  };

  return (
    <>
      <Container>
        <ContentGrid>
          <Heading
            initial={{ opacity: 0, y: 20 }}
            transition={{ delay: 0.3, duration: 0.6 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            {bfloData.heading}
          </Heading>

          <Img
            initial={{ opacity: 0, y: 50 }}
            transition={{ delay: 0.7, duration: 0.6 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            src={bfloData.img}
            alt={bfloData.alt}
          />

          <Section1>
            <motion.ul
              initial={{ opacity: 0 }}
              transition={{ delay: 0.7, duration: 0.6 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
            >
              <span>{bfloData.title1}</span>
              {bfloData.listItems1.map((li) => (
                <motion.li
                  initial={{ opacity: 0, y: 5 }}
                  transition={{ delay: 1, duration: 0.6 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, margin: "70px" }}
                >
                  {li}
                </motion.li>
              ))}
            </motion.ul>
          </Section1>

          <Section2>
            <motion.ul
              initial={{ opacity: 0 }}
              transition={{ delay: 0.7, duration: 0.6 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
            >
              <span>{bfloData.title2}</span>
              {bfloData.listItems2.map((li) => (
                <motion.li
                  initial={{ opacity: 0, y: 5 }}
                  transition={{ delay: 1, duration: 0.6 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, margin: "100px" }}
                >
                  {li}
                </motion.li>
              ))}
            </motion.ul>
          </Section2>
          <Section3>
            <motion.ul
              initial={{ opacity: 0 }}
              transition={{ delay: 0.7, duration: 0.6 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
            >
              <span>{bfloData.title3}</span>

              {bfloData.listItems3.map((li) => (
                <motion.li
                  initial={{ opacity: 0, y: 5 }}
                  transition={{ delay: 1, duration: 0.6 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, margin: "100px" }}
                >
                  {li}
                </motion.li>
              ))}
            </motion.ul>
          </Section3>

          <Section4>
            <motion.ul
              initial={{ opacity: 0 }}
              transition={{ delay: 0.7, duration: 0.6 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
            >
              <span>{bfloData.title4}</span>
              <motion.p
                initial={{ opacity: 0, y: 5 }}
                transition={{ delay: 1, duration: 0.6 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, margin: "70px" }}
              >
                {bfloData.listItems4}
              </motion.p>
            </motion.ul>
          </Section4>
        </ContentGrid>
        <GalleryGrid>
          <ImgContainer
            gridcolumn={vid.gridcolumn}
            gridrow={vid.gridrow}
            initial={{ opacity: 0, y: 50 }}
            transition={{ delay: 0.3, duration: 0.6 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <Vid
              className="bflo-video"
              loop={true}
              autoPlay={true}
              muted={true}
              playsInline={true}
            >
              <source src={vid.img} type="video/mp4" />
            </Vid>
          </ImgContainer>

          {imgGal.map((item) => {
            return (
              <ImgContainer
                gridcolumn={item.gridcolumn}
                gridrow={item.gridrow}
                initial={{ opacity: 0, y: 50 }}
                transition={{ delay: 0.3, duration: 0.6 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
              >
                <GridImg src={item.src} />
              </ImgContainer>
            );
          })}
          <ImgContainer
            gridcolumn={vidLG.gridcolumn}
            gridrow={vidLG.gridrow}
            initial={{ opacity: 0, y: 50 }}
            transition={{ delay: 0.3, duration: 0.6 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <Vid
              className="bflo-video"
              loop={true}
              autoPlay={true}
              muted={true}
              playsInline={true}
            >
              <source src={vidLG.img} type="video/mp4" />
            </Vid>
          </ImgContainer>
        </GalleryGrid>
        <ProjectNav {...BuffaloNav} />
      </Container>
    </>
  );
};

export default Buffalo;
