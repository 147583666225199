import { motion } from "framer-motion";
import styled from "styled-components";

export const CButtonContainer = styled(motion.div) `
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 50;

    .btn-text {
    padding-top: .5em;
    font-size: 1em; 
    cursor: pointer;
    font-weight:400;
    color: var(--background-color);
}

`
export const CircleContainer = styled.div `
 display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color:var(--background-color);
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    transform: rotate(180deg);

    svg {
    transform: scale(1.1);
}
svg path {
    stroke: var(--text-color);
}
.arrow-container path {
    overflow: hidden;
    height: 50px;
    width: 50px;
    transform: rotate(180);
    color: white;

}


`