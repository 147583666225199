import React from "react";
import { NavLink } from "react-router-dom";
import { ProNavContainer } from "./ProjectNavStyles";
import { ContentButton } from "../Card/CardStyles";

const ProjectNav = ({ id, prevLink, nextLink }) => {
  return (
    <ProNavContainer>
      <p>
        <span></span>
        <NavLink to={prevLink}>
          <ContentButton>Prev</ContentButton>
        </NavLink>
      </p>

      <p>
        <span></span>

        <NavLink to={nextLink}>
          <ContentButton>Next</ContentButton>
        </NavLink>
      </p>
    </ProNavContainer>
  );
};

export default ProjectNav;
