export const imgGal = [
  {
    id: "1",
    src: "./images/cal/calflatlay.jpg",
    gridcolumn: "1/8",
    gridrow: "1/3",
  },
  {
    id: "2",
    src: "./images/cal/caldetail.jpg",
    gridcolumn: "5/13",
    gridrow: "3/5",
  },
  {
    id: "3",
    src: "./images/cal/01.jpg",
    gridcolumn: "8/13",
    gridrow: "1/2",
  },
  {
    id: "4",
    src: "./images/cal/02.jpg",
    gridcolumn: "8/13",
    gridrow: "2/3",
  },
  {
    id: "5",
    src: "./images/cal/03.jpg",
    gridcolumn: "1/5",
    gridrow: "3/4",
  },
  {
    id: "6",
    src: "./images/cal/04.jpg",
    gridcolumn: "1/5",
    gridrow: "4/5",
  },
  {
    id: "7",
    src: "./images/cal/05.jpg",
    gridcolumn: "1/4",
    gridrow: "5/6",
  },
  {
    id: "8",
    src: "./images/cal/06.jpg",
    gridcolumn: "4/7",
    gridrow: "5/6",
  },
  {
    id: "9",
    src: "./images/cal/07.jpg",
    gridcolumn: "7/10",
    gridrow: "5/6",
  },
  {
    id: "10",
    src: "./images/cal/08.jpg",
    gridcolumn: "10/13",
    gridrow: "5/6",
  },
  {
    id: "11",
    src: "./images/cal/09.jpg",
    gridcolumn: "8/13",
    gridrow: "6/7",
  },
  {
    id: "12",
    src: "./images/cal/10.jpg",
    gridcolumn: "8/13",
    gridrow: "7/8",
  },
  {
    id: "13",
    src: "./images/cal/11.jpg",
    gridcolumn: "1/7",
    gridrow: "8/9",
  },
  {
    id: "14",
    src: "./images/cal/12.jpg",
    gridcolumn: "7/13",
    gridrow: "8/9",
  },
  {
    id: "15",
    src: "./images/cal/calmultiple.jpg",
    gridcolumn: "1/8",
    gridrow: "6/8",
  },
];
