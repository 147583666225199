import React from "react";
import { motion } from "framer-motion";
import { Container } from "../../globalStyles";
import { BioSection, DetailSection, ListContainer } from "./InfoPageStyles";
import { skills, bioInfo } from "../../data/InfoPageData";

const InfoPage = () => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
        delayChildren: 0.5,
      },
    },
  };

  const itemA = {
    hidden: { opacity: 0, x: 150 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        duration: 1,
        ease: [0.6, 0.05, -0.01, 0.0],
      },
    },
  };

  const itemB = {
    hidden: { opacity: 0, x: -150 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        duration: 1,
        ease: [0.6, 0.05, -0.01, 0.0],
      },
    },
  };

  const itemC = {
    hidden: { opacity: 0, y: -30 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 1.3,
      },
    },
  };
  const paragraph = {
    hidden: { opacity: 0, y: 30 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        ease: [0.6, 0.05, -0.01, 0.0],
        delay: 2,
      },
    },
  };

  const paragraphAlt = {
    hidden: { opacity: 0, y: 30 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        ease: [0.6, 0.05, -0.01, 0.0],
      },
    },
  };

  const imageAni = {
    hidden: { opacity: 0, scale: 1 },
    show: {
      opacity: 1,
      scale: 1.1,
      transition: {
        duration: 1,
        ease: [0.6, 0.05, -0.01, 0.0],
      },
    },
  };

  return (
    <Container>
      <BioSection>
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className="info-tagline"
        >
          <motion.h3 variants={itemA}>Multidisciplinary</motion.h3>
          <motion.h3 variants={itemB}>
            designer <motion.span variants={itemC}>and</motion.span>
          </motion.h3>
          <motion.h3 variants={itemA}>developer</motion.h3>
        </motion.div>
        <motion.p initial="hidden" animate="show" variants={paragraph}>
          Combining creative disciplines to bring about thoughtful and refined
          designs. I’m interested in visually driven design work using a diverse
          range of technologies and mediums.
        </motion.p>
      </BioSection>

      <DetailSection>
        <h2>Capabilities</h2>
        <div className="skills-container">
          {skills.map((data, index) => (
            <ListContainer
              initial="hidden"
              whileInView="show"
              viewport={{ once: true }}
              variants={paragraphAlt}
              key={index}
            >
              <h3>{data.title}</h3>
              <ul>
                {data.list.map((item, itemIndex) => (
                  <li>{item}</li>
                ))}
              </ul>
            </ListContainer>
          ))}
        </div>
      </DetailSection>

      <DetailSection>
        <h2>Brian Munro</h2>
        <div className="detail-container">
          {/* <div className="info-image">
            <motion.img
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, margin: "-100px" }}
              variants={imageAni}
              src={bioInfo.img}
            />
          </div> */}
          <motion.div
            className="detail-text"
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            variants={paragraphAlt}
          >
            <motion.p>
              Pleasantly situated at the cross-section of technology, art, and
              design. I believe that the language of design helps to permeate
              many barriers often presented by conventional forms of
              communication. <br />
              <br />
              We are consistently evolving in the ways in which we interact and
              relate to one another. I think that by combining technology, art,
              and design, we are better able to form deeper understanding and
              connections.
            </motion.p>
            <motion.p>
              My goal is to build appealing web experiences for a variety of
              user bases. When I am not working on projects for the web, I’m
              often spending time working on designing for print, illustration,
              photography, videography, and screenprinting.
            </motion.p>
          </motion.div>
        </div>
      </DetailSection>
    </Container>
  );
};

export default InfoPage;
