import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContentGrid = styled.div `
    display:grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 2 auto;
    grid-gap:10px;
    overflow: hidden;
    padding-top: 4em;
    @media screen and (max-width: 768px){
        padding-top: 2em;
    }
    & ul {
        color: var(--second-text-color);
        text-transform: uppercase; 
        font-size: .9rem; 
    }
    & ul, li {
    list-style-type: none;
    @media screen and (max-width: 768px){
        /* font-size:.8rem; */
    }
    }
    & li, p {
    color: var(--text-color);
    text-transform: none;
    font-size: 1.1rem;
    line-height: 24px;
    @media screen and (max-width: 768px){
        /* font-size:.8rem;
        line-height:18px; */
    }
    /* line-height: 1.1em; */
    }

    & span {
        line-height: 2em;
    } 
 
    

`;
export const ImgWrapper = styled.div `

`;

export const Img = styled(motion.img)`
    grid-column: 1/5;
    grid-row: 2/3;
    width:100%;
    margin-bottom: 10em;
    margin-top: 2em;
    @media screen and (max-width: 768px) {
    margin-bottom: 5em;
}


`;
export const Heading = styled(motion.h2)`
    grid-column: 4/5;
    grid-row: 1/2;
    font-size: 3.5rem;
    text-align: right;
    text-transform: uppercase;
    /* padding-bottom: .5em; */
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
@media screen and (max-width: 768px) {
    font-size: 6.5vw;
    grid-column: 2/5;
}
    

`;

export const Section1 = styled(motion.div)`
    grid-column: 2/3;
    grid-row: 1/2;
    @media screen and (max-width: 768px) {
    grid-column:1/4;
    & ul, li {
        /* font-size: .9rem; */
    }
    

}
    

`;
export const Section2 = styled(motion.div)`
    grid-column: 2/3;
    grid-row: 3/4;
    @media screen and (max-width: 768px) {
    grid-column: 3/5;
    overflow: hidden;

}

`;
export const Section3 = styled(motion.div)`
    grid-column:1/2;
    grid-row: 3/4;
    @media screen and (max-width: 768px) {
    grid-column: 1/3;
    

}

`;
export const Section4 = styled(motion.div)`
    grid-column: 3/5;
    grid-row: 3/4;
    padding-left: 2em;
    @media screen and (max-width: 768px) {
    grid-column: 1/5;
    grid-row: 4/5;
    overflow: hidden;
    padding-left:0;

}

`;
export const ProjectInfo = styled.div `
 
background-color: yellow;



:nth-of-type(1) {
    background-color:yellow;
    grid-column: 3/4;
    grid-row:1/2;
    
}

:nth-of-type(2) {
    background-color:pink;
    grid-column: 1/3;
    grid-row:1/2;
}

ul {
    list-style-type: none
}

ul li {
    
}

p {}

`;


