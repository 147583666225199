import React from "react";
import { Container } from "../globalStyles";
import {
  ContentGrid,
  Img,
  Heading,
  Section1,
  Section2,
  Section3,
  Section4,
} from "../components/PageHero/PageHeroStyles";
import {
  GalleryGridAlt,
  GridImg,
  ImgContainerAlt,
} from "../components/PageGallery/PageGalleryStyles";
import { imgGal } from "../data/CalendarData";
import { motion } from "framer-motion";
import ProjectNav from "../components/ProjectNav/ProjectNav";
import { CalNav } from "../components/ProjectNav/ProjectNavData";

const Calendar = () => {
  const calData = {
    heading: (
      <>
        {"Calendar"}
        <br />
        {"2021 Print"}
      </>
    ),
    img: "./images/cal/cal2021.jpg",
    alt: "2021 calendar project image",
    title1: "Role",
    listItems1: [
      "Layout Design",
      "Typography",
      "Screen Printing",
      "Illustration",
    ],
    title2: "Deliverables",
    listItems2: ["Screenprinted Calendar"],
    title3: "Client",
    listItems3: ["Personal Project"],
    title4: "Overview",
    listItems4:
      'The 2021 calendar was designed using anthropomorphized numbers to emphasize the range of emotions the pandemic has bubbled to the surface. The prints are poster format hand screen printed on card stock measuring 14 "x 22"',
  };

  return (
    <>
      <Container>
        <ContentGrid>
          <Heading
            initial={{ opacity: 0, y: 20 }}
            transition={{ delay: 0.3, duration: 0.6 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            {calData.heading}
          </Heading>

          <Img
            initial={{ opacity: 0, y: 50 }}
            transition={{ delay: 0.7, duration: 0.6 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            src={calData.img}
            alt={calData.alt}
          />

          <Section1>
            <motion.ul
              initial={{ opacity: 0 }}
              transition={{ delay: 0.7, duration: 0.6 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
            >
              <span>{calData.title1}</span>
              {calData.listItems1.map((li) => (
                <motion.li
                  initial={{ opacity: 0, y: 5 }}
                  transition={{ delay: 1, duration: 0.6 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, margin: "70px" }}
                >
                  {li}
                </motion.li>
              ))}
            </motion.ul>
          </Section1>

          <Section2>
            <motion.ul
              initial={{ opacity: 0 }}
              transition={{ delay: 0.7, duration: 0.6 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
            >
              <span>{calData.title2}</span>
              {calData.listItems2.map((li) => (
                <motion.li
                  initial={{ opacity: 0, y: 5 }}
                  transition={{ delay: 1, duration: 0.6 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, margin: "100px" }}
                >
                  {li}
                </motion.li>
              ))}
            </motion.ul>
          </Section2>
          <Section3>
            <motion.ul
              initial={{ opacity: 0 }}
              transition={{ delay: 0.7, duration: 0.6 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
            >
              <span>{calData.title3}</span>

              {calData.listItems3.map((li) => (
                <motion.li
                  initial={{ opacity: 0, y: 5 }}
                  transition={{ delay: 1, duration: 0.6 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, margin: "100px" }}
                >
                  {li}
                </motion.li>
              ))}
            </motion.ul>
          </Section3>

          <Section4>
            <motion.ul
              initial={{ opacity: 0 }}
              transition={{ delay: 0.7, duration: 0.6 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
            >
              <span>{calData.title4}</span>
              <motion.p
                initial={{ opacity: 0, y: 5 }}
                transition={{ delay: 1, duration: 0.6 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, margin: "70px" }}
              >
                {calData.listItems4}
              </motion.p>
            </motion.ul>
          </Section4>
        </ContentGrid>

        <GalleryGridAlt>
          {imgGal.map((item) => {
            return (
              <ImgContainerAlt
                gridcolumn={item.gridcolumn}
                gridrow={item.gridrow}
                initial={{ opacity: 0, y: 50 }}
                transition={{ delay: 0.3, duration: 0.6 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
              >
                <GridImg src={item.src} />
              </ImgContainerAlt>
            );
          })}
        </GalleryGridAlt>
        <ProjectNav {...CalNav} />
      </Container>
    </>
  );
};

export default Calendar;
