import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Clock from "./Clock";

const Locale = () => {
  return (
    <div>
      <span>
        Working <br /> Remote In
      </span>
      <h3>Buffalo, NY</h3>
    </div>
  );
};

export default Locale;

const texts = [<Locale />, <Clock />];
const variants = {
  enter: (direction) => {
    return {
      y: 20,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    y: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      y: -20,
      opacity: 0,
    };
  },
};

export const TextLoop = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      let next = index + 1;
      if (next === texts.length) {
        next = 0;
      }
      setIndex(next);
    }, 5 * 1000);
  }, [index, setIndex]);

  return (
    <div className="location-container">
      <AnimatePresence>
        <motion.div
          style={{ position: "absolute" }}
          variants={variants}
          key={index}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            y: { type: "spring", stiffness: 400, damping: 50 },
            opacity: { duration: 0.3 },
          }}
        >
          {texts[index]}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
