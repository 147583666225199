import styled from "styled-components";
import { motion } from "framer-motion";

export const GalleryGrid = styled.div`
  margin-top: 10em;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, auto);
  grid-gap: 2em;
`;

export const ImgContainer = styled(motion.div)`
  grid-column: ${({ gridcolumn }) => (gridcolumn ? gridcolumn : "")};
  grid-row: ${({ gridrow }) => (gridrow ? gridrow : "")};
  @media screen and (max-width: 768px) {
    grid-column: 1/8;
    grid-row: auto;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    width: 100vw;
    overflow: hidden;
  }
`;

export const GridImg = styled(motion.img)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
`;

export const VidContainer = styled(motion.div)`
  grid-column: ${({ gridcolumn }) => (gridcolumn ? gridcolumn : "")};
  grid-row: ${({ gridrow }) => (gridrow ? gridrow : "")};
  @media screen and (max-width: 768px) {
    grid-column: 1/8;
    grid-row: auto;
  }
`;

export const Vid = styled.video`
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
`;

export const GalleryGridAlt = styled.div`
  margin: 10em auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(9, auto);
  grid-gap: 2em;
  width: 100%;
  @media screen and (max-width: 960px) {
    padding: 0 0px;
    grid-column-gap: 0;
  }
`;

export const ImgContainerAlt = styled(motion.div)`
  grid-column: ${({ gridcolumn }) => (gridcolumn ? gridcolumn : "")};
  grid-row: ${({ gridrow }) => (gridrow ? gridrow : "")};
  @media screen and (max-width: 768px) {
    grid-column: 1/13;
    grid-row: auto;
    /* margin-left: -50vw;
    margin-right: -50vw; */
    max-width: 100%;
    /* position: relative; */
    /* left: 50%;
    right: 50%;
    width: 100%; */
    margin-left: auto;
    margin-right: auto;
  }
`;
