import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'Montserrat', sans-serif; */
}
:root {
  --black: rgb(23, 24, 24);
  --white: rgb(243, 243, 243);
  --grey: rgb(85, 85, 85);
  --lightgrey: rgb(146, 145, 145);
  
}

html{
	background: transparent;

}


html, body {
  font-size:16px;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overscroll-behavior: none;
  /* overflow-x: hidden; */
  font-family: 'Helvetica Neue', 'Helvetica', sans-serif;
}
body {
  min-height:100vh;
  display: flex;
  flex-direction: column;
  background: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease-in-out, color 0.6s ease-in-out;

}
body.light {
  --background-color: var(--white);
  --text-color: var(--black);
  --second-text-color: var(--grey);
}

body.dark {
  --background-color: var(--black);
  --text-color: var(--white);
  --second-text-color: var(--lightgrey);
}
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 50px;
  @media screen and (max-width: 960px) {
    padding: 0 20px;
  }
`;

export const Section = styled.div`
  color: #fff;
  padding: 160px 0;
`;

export const Heading = styled.h2`
  margin-bottom: 1.4rem;
  font-size: 3rem;
  text-align: center;
  line-height: 1.1;
  font-weight: 600;
`;

export default GlobalStyle;
