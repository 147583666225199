import { motion } from "framer-motion";
import React from "react";
import { NavLink } from "react-router-dom";
import { Container } from "../../globalStyles";
import {
  InfoParagraph,
  BannerTitle,
  Headline,
  FirstName,
  LastName,
  Subtext,
  Titles,
  WorksTitle,
} from "../Hero/HeroStyles";
import { ContentButton } from "../Card/CardStyles";

const parent = {
  initial: { x: -150, y: "50vh" },
  animate: {
    x: 0,
    y: "0vh",
    transition: {
      staggerChildren: 0.3,
      y: {
        type: "spring",
        damping: 20,
        mass: 0.25,
        stiffness: 100,
        delay: 2,
        // ease: [0.6, 0.05, -0.01, 0.0],
      },
    },
  },
};

const child = {
  initial: { y: 600, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 1.2,
      ease: [0.6, 0.05, -0.01, 0.0],
    },
  },
};

const fadeIn = {
  initial: { opacity: 0, x: -150 },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      duration: 1.2,
      ease: [0.6, 0.05, -0.01, 0.0],
    },
  },
};

const subtext = {
  initial: { y: 30, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.4,
    },
  },
};

const Hero = () => {
  return (
    <Container>
      <BannerTitle variants={parent} initial="initial" animate="animate">
        <Titles>
          <FirstName>
            <Headline variants={fadeIn}>Brian</Headline>
          </FirstName>
          <LastName>
            <Headline variants={fadeIn}>Munro</Headline>
          </LastName>

          <Subtext>
            <motion.span variants={subtext}>multidisciplinary</motion.span>
            <motion.span variants={subtext}>designer</motion.span>
            <motion.span variants={subtext}>developer</motion.span>
          </Subtext>
        </Titles>
        <InfoParagraph
          initial={{ opacity: 0 }}
          transition={{
            delay: 2.9,
            duration: 2,
            ease: [0.6, 0.01, -0.05, 0.9],
          }}
          animate={{ opacity: 1 }}
        >
          <p>
            I am a multidisciplinary designer and developer, combining creative
            disciplines to bring about thoughtful and refined designs. I’m
            interested in visually driven design work using a diverse range of
            technologies and mediums.
          </p>

          <NavLink to="/info">
            <ContentButton
              initial={{ opacity: 0 }}
              transition={{
                delay: 3.2,
                duration: 2,
                ease: [0.6, 0.01, -0.05, 0.9],
              }}
              animate={{ opacity: 1 }}
            >
              Read More
            </ContentButton>
          </NavLink>
        </InfoParagraph>
        <WorksTitle
          initial={{ opacity: 0 }}
          transition={{
            delay: 3.5,
            duration: 2,
            ease: [0.6, 0.01, -0.05, 0.9],
          }}
          animate={{ opacity: 1 }}
        >
          Selected Works
        </WorksTitle>
      </BannerTitle>
    </Container>
  );
};

export default Hero;
