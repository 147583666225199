import React from "react";
import { Container } from "../globalStyles";
import {
  ContentGrid,
  Img,
  Heading,
  Section1,
  Section2,
  Section3,
  Section4,
} from "../components/PageHero/PageHeroStyles";
import {
  GalleryGrid,
  Vid,
  GridImg,
  ImgContainer,
} from "../components/PageGallery/PageGalleryStyles";
import { imgGal, vid } from "../data/ToaGalleryData";
import { motion } from "framer-motion";
import ProjectNav from "../components/ProjectNav/ProjectNav";
import { TheoriesNav } from "../components/ProjectNav/ProjectNavData";

const Theories = () => {
  const toaData = {
    heading: (
      <>
        {" "}
        {"Theories"}
        <br />
        {"Skateboards"}
      </>
    ),
    img: "./images/toa/theories.jpg",
    alt: "Theories Skateboards project image",
    title1: "Role",
    listItems1: ["Graphic Design", "Illustration", "Layout", "Apparel Design"],
    title2: "Deliverables",
    listItems2: ["Graphic for", "Skateboards", "Apparel", "TechDeck™"],
    title3: "Client",
    listItems3: ["Theories Skateboards"],
    title4: "Overview",
    listItems4:
      'The "Breakthrough" graphic was developed to be featured in Theories Fall/Winter Catalogue. The design work included, a skateboard graphic, sweatshirt, long and short sleeve tees. These items were sold globally. In addition, the skateboard graphic was licensed to TechDeck for a fingerboard release.',
  };

  return (
    <>
      <Container>
        <ContentGrid>
          <Heading
            initial={{ opacity: 0, y: 20 }}
            transition={{ delay: 0.3, duration: 0.6 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            {toaData.heading}
          </Heading>

          <Img
            initial={{ opacity: 0, y: 50 }}
            transition={{ delay: 0.7, duration: 0.6 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            src={toaData.img}
            alt={toaData.alt}
          />

          <Section1>
            <motion.ul
              initial={{ opacity: 0 }}
              transition={{ delay: 0.7, duration: 0.6 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true, margin: "70px" }}
            >
              <motion.span>{toaData.title1}</motion.span>
              {toaData.listItems1.map((li) => (
                <motion.li
                  initial={{ opacity: 0, y: 5 }}
                  transition={{ delay: 1, duration: 0.6 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, margin: "70px" }}
                >
                  {li}
                </motion.li>
              ))}
            </motion.ul>
          </Section1>

          <Section2>
            <motion.ul
              initial={{ opacity: 0 }}
              transition={{ delay: 0.7, duration: 0.6 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
            >
              <span>{toaData.title2}</span>
              {toaData.listItems2.map((li) => (
                <motion.li
                  initial={{ opacity: 0, y: 5 }}
                  transition={{ delay: 1, duration: 0.6 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, margin: "100px" }}
                >
                  {li}
                </motion.li>
              ))}
            </motion.ul>
          </Section2>

          <Section3>
            <motion.ul
              initial={{ opacity: 0 }}
              transition={{ delay: 0.7, duration: 0.6 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
            >
              <span>{toaData.title3}</span>

              {toaData.listItems3.map((li) => (
                <motion.li
                  initial={{ opacity: 0, y: 5 }}
                  transition={{ delay: 1, duration: 0.6 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, margin: "100px" }}
                >
                  {li}
                </motion.li>
              ))}
            </motion.ul>
          </Section3>

          <Section4>
            <motion.ul
              initial={{ opacity: 0 }}
              transition={{ delay: 0.7, duration: 0.6 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
            >
              <span>{toaData.title4}</span>
              <motion.p
                initial={{ opacity: 0, y: 5 }}
                transition={{ delay: 1, duration: 0.6 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, margin: "70px" }}
              >
                {toaData.listItems4}
              </motion.p>
            </motion.ul>
          </Section4>
        </ContentGrid>

        <GalleryGrid>
          <ImgContainer
            gridcolumn={vid.gridcolumn}
            gridrow={vid.gridrow}
            initial={{ opacity: 0, y: 50 }}
            transition={{ delay: 0.3, duration: 0.6 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <Vid loop={true} autoPlay={true} muted={true} playsInline={true}>
              <source src={vid.img} type="video/mp4" />
            </Vid>
          </ImgContainer>

          {imgGal.map((item) => {
            return (
              <ImgContainer
                gridcolumn={item.gridcolumn}
                gridrow={item.gridrow}
                initial={{ opacity: 0, y: 50 }}
                transition={{ delay: 0.3, duration: 0.6 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
              >
                <GridImg src={item.src} />
              </ImgContainer>
            );
          })}
        </GalleryGrid>
        <ProjectNav {...TheoriesNav} />
      </Container>
    </>
  );
};

export default Theories;
