import styled from "styled-components";

export const DarkButton = styled.div`
  input[type="checkbox"] {
    visibility: hidden;
    &:checked + label {
      transform: rotate(360deg);
      background-color: var(--background-color);
      &:before {
        transform: translateX(12px);
        background-color: var(--text-color);
      }
    }
  }

  label {
    display: flex;
    width: 30px;
    height: 15px;
    border: 2px solid var(--text-color);
    border-radius: 99em;
    position: relative;
    transition: transform 0.75s ease-in-out;
    transform-origin: 50% 50%;
    cursor: pointer;

    &:before {
      transition: transform 0.75s ease;
      transition-delay: 0.5s;
      content: "";
      display: block;
      position: absolute;
      width: 11px;
      height: 11px;
      background-color: var(--text-color);
      border-radius: 50%;
      top: 0px;
      left: 2px;
    }
  }
`;
