import styled from "styled-components";
import { motion } from "framer-motion";

export const BioSection = styled(motion.div)`
  padding-top: 100px;
  min-height: 90vh;
  @media screen and (max-width: 768px) {
    min-height: 90vh;
  }

  .info-tagline {
    padding-bottom: 50px;
    width: 100%;
  }

  h3 {
    text-transform: uppercase;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    font-size: 3.8rem;
    font-weight: 700;

    span {
      text-transform: lowercase;
      /* color: var(--second-text-color); */
      font-style: italic;
      display: flex;
      width: 100%;
      justify-content: flex-start;
      font-size: 3.8rem;
      font-weight: 300;
      padding-left: 0.3em;
    }

    @media screen and (max-width: 768px) {
      /* font-size: 2rem; */
      font-size: calc(4vw + 1rem);
      span {
        /* font-size: 2rem; */
        font-size: calc(4vw + 1rem);
      }
    }
  }

  p {
    font-size: 1.5rem;
    width: 60%;
    line-height: 2.4rem;
    padding-top: 3em;
    padding-bottom: 3em;
    @media screen and (max-width: 768px) {
      width: 100%;
      padding-left: 0;
      padding-top: 1em;
    }
  }
`;

export const DetailSection = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  /* border-top: 2px solid var(--second-text-color); */
  padding-bottom: 50px;

  h2 {
    display: flex;
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: 700;
    width: 100%;
    justify-content: flex-start;
    padding-bottom: 50px;
    padding-top: 0.5em;
    padding-left: 1rem;

    @media screen and (max-width: 768px) {
      width: 100%;
      /* justify-content: flex-end; */
      font-size: 2.5rem;
      flex-wrap: wrap;
    }
  }
  ul,
  li {
    list-style: none;
  }

  .info-image {
    width: 0%;
    overflow: hidden;
    visibility: hidden;

    @media screen and (max-width: 768px) {
      width: 0%;
      display: hidden;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      overflow: hidden;
    }
  }

  p {
    font-size: 1.3rem;
    line-height: 1.6rem;
    padding-left: 1em;
    width: 50%;
    @media screen and (max-width: 768px) {
      width: 100%;
      font-size: 1.3rem;
      line-height: 1.5rem;
      padding-top: 1em;
      padding-left: 0em;
    }
    :nth-of-type(1) {
    }
  }

  .detail-text {
    display: flex;
    flex-direction: row;
    width: 90%;
    /* margin: 0 auto; */
    justify-content: left;
    @media screen and (max-width: 768px) {
      width: 100%;
      flex-direction: column;
    }
  }
  .detail-container {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .skills-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
`;
export const ListContainer = styled(motion.div)`
  display: flex;

  h3 {
    padding: 1em;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--second-text-color);
    display: flex;
    justify-content: flex-start;

    @media screen and (max-width: 768px) {
      color: var(--second-text-color);
      text-transform: uppercase;
      font-size: 1.1rem;
      font-weight: 400;
      padding-left: 0;
    }
  }
  ul {
    padding: 1em;
    padding-bottom: 3em;
    /* width: 50%; */
    @media screen and (max-width: 768px) {
      /* width: 100%; */
      padding-top: 0;
      padding-left: 0;
    }
  }
  li {
    line-height: 1.3rem;
    font-size: 1.1rem;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;
