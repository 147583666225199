import React, { useState } from "react";
import { Container } from "../../globalStyles";
import {
  FooterContainer,
  FooterLink,
  FlexContainer,
  FlexLeft,
  FlexRight,
  Copyright,
  HeadingSplit,
  ContactInfo,
} from "./FooterStyles";
import { TextLoop } from "./Location";
import CircleButton from "./CircleButton";
import { GridImg } from "../PageGallery/PageGalleryStyles";
import IconMail from "../EmailButton/IconMail";
import IconGit from "./IconGit";

const Footer = () => {
  const [scrollTop, setScrollTop] = useState(false);
  const changeScrollTop = () => {
    if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }
  };

  window.addEventListener("scroll", changeScrollTop);

  return (
    <FooterContainer>
      <Container>
        <FlexContainer>
          <FlexLeft>
            <HeadingSplit>
              <h2>Let's Work</h2>
              <h2>Together</h2>
            </HeadingSplit>
            <ContactInfo>
              <h4>Get In Touch</h4>
              <FooterLink>
                <IconMail />
                <div>
                  {/* <p>mmmunrooo@gmail.com</p> */}
                  <p>
                    <a
                      href={
                        "mailto:mmmunrooo@gmail.com?subject=Hello from portfolio site."
                      }
                    >
                      mmmunrooo@gmail.com
                    </a>
                  </p>
                </div>
              </FooterLink>
              <FooterLink>
                <IconGit />
                <div>
                  <p>
                    <a
                      href="https://github.com/brimun"
                      target="_blank"
                      rel="noreferrer"
                    >
                      GitHub/BriMun
                    </a>
                  </p>
                </div>
              </FooterLink>
            </ContactInfo>
          </FlexLeft>
          <FlexRight>
            <GridImg src="/images/bflocityhall.jpg" alt="city hall bw" />
            <TextLoop />
          </FlexRight>
        </FlexContainer>

        <div className="up-container">
          <div className={scrollTop ? "scroll-top active" : "scroll-top"}>
            <CircleButton />
          </div>
        </div>
        <Copyright>
          <p>©2023 Brian Munro All Rights Reserved</p>
        </Copyright>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
