export const cardOne = {
  id: "projects",
  topLine: {
    text: "Product Design",
  },
  headline: (
    <>
      {"Theories"}
      <br /> {"Skateboards"}
    </>
  ),
  description: (
    <>
      {"Product design for"} <br /> {"Skateboards, Apparel"} <br />
      {" and TechDeck"}&#8482;
    </>
  ),
  buttonLabel: "View Project",
  linkTo: "/theories",
  imgStart: "start",
  img: "./images/toa/theories.jpg",
  start: "true",
};

export const cardTwo = {
  reverse: true,
  topLine: {
    text: "Development and Design",
  },
  headline: "Buffalo Heritage",
  description: (
    <>
      {" "}
      {"Ecommerce site for"} <br /> {"Buffalo NY T-Shirt"} <br />{" "}
      {"Logo Design"}
    </>
  ),
  buttonLabel: "View Project",
  linkTo: "/buffalo",
  imgStart: "start",
  img: "./images/bflo/bflo2.jpg",
  start: "true",
};

export const cardThree = {
  topLine: {
    text: "Development and Design",
  },
  headline: "Sandy's Petshop",
  description: (
    <>
      {"Brochure website case study"}
      <br /> {"for pet grooming"}
      <br />
      {"appointments"}
    </>
  ),
  buttonLabel: "View Project",

  linkTo: "/sandys",
  imgStart: "",
  img: "./images/sp/sandys1.jpg",
  start: "true",
};

export const cardFour = {
  reverse: true,
  topLine: {
    text: "Typography Design",
  },
  headline: "2021 Calendar Print",
  description: (
    <>
      {"Anthropomorphized 2021 Calendar"}
      <br /> {"Hand screen-printed. Strange"}
      <br />
      {"numbers for strange times"}
    </>
  ),
  buttonLabel: "View Project",

  linkTo: "/calendar",
  imgStart: "",
  img: "./images/cal/cal2021.jpg",
  start: "true",
};
