import styled from "styled-components";
import { motion } from "framer-motion";

export const HeroTop = styled(motion.div)``;

export const BannerTitle = styled(motion.div)`
  height: 100%;
  min-height: 95vh;
  /* padding-bottom: 40px; */

  @media screen and (max-width: 768px) {
    margin-top: 50px;
    min-height: 80vh;
  }
`;

export const FirstName = styled(motion.div)`
  order: 1;
`;
export const LastName = styled(motion.div)`
  order: 3;
`;

export const Headline = styled(motion.span)`
  display: flex;
  flex-direction: column;
  font-size: calc(14vw + 1rem);
  font-weight: 900;
  line-height: 0.86;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    font-size: calc(13vw + 1rem);
    line-height: 0.86;
  }
  @media screen and (min-width: 1400px) {
    font-size: calc(12vw + 1rem);
    line-height: 0.96;
  }
`;

export const Subtext = styled(motion.span)`
  order: 2;
  padding-top: 1rem;

  @media screen and (max-width: 768px) {
    padding-top: 0.5rem;
  }
  @media screen and (min-width: 1400px) {
    padding-top: 2.5rem;
  }
  & span {
    display: block;
    padding-bottom: 0.4rem;

    font-size: calc(1vw + 1rem);
    font-weight: 300;
    line-height: 1;
    @media screen and (max-width: 768px) {
      font-size: calc(1vw + 0.5rem);
      line-height: 0.8;
    }
    @media screen and (min-width: 1400px) {
      padding-bottom: 1rem;
    }
  }
`;
export const Titles = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const InfoParagraph = styled(motion.div)`
  @media screen and (max-width: 768px) {
  }

  & p {
    max-width: 50%;
    font-size: 1.5rem;
    padding: 1em 0;
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
      max-width: 100%;
    }
  }
`;
export const WorksTitle = styled(motion.h2)`
  padding-top: 200px;
  font-size: calc(2vw + 2.5rem);
  line-height: 1.1;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  justify-content: right;
  color: var(--text-color);
  @media screen and (max-width: 768px) {
    padding-top: 100px;
  }
`;
