export const vid = {
  img: "./images/toa/LoopDeck.mp4",
  gridcolumn: "1/4",
  gridrow: "1/2",
};

export const imgGal = [
  {
    id: "1",
    src: "./images/toa/theoriesdeckcloseup.jpg",
    gridcolumn: "4/8",
    gridrow: "1/2",
  },
  {
    id: "2",
    src: "./images/toa/TheoriesTechDeck.jpg",
    gridcolumn: "1/8",
    gridrow: "2/3",
  },
  {
    id: "3",
    src: "./images/toa/t-shirt.jpg",
    gridcolumn: "1/4",
    gridrow: "3/4",
  },
  {
    id: "4",
    src: "./images/toa/t-shirt1.jpg",
    gridcolumn: "4/8",
    gridrow: "3/4",
  },
  {
    id: "5",
    src: "../images/toa/longsleeve.jpg",
    gridcolumn: "1/4",
    gridrow: "4/5",
  },
  {
    id: "6",
    src: "../images/toa/longsleeve1.jpg",
    gridcolumn: "4/8",
    gridrow: "4/5",
  },
  {
    id: "7",
    src: "../images/toa/sweatshirt.jpg",
    gridcolumn: "1/4",
    gridrow: "5/6",
  },
  {
    id: "8",
    src: "../images/toa/sweatshirt1.jpg",
    gridcolumn: "4/8",
    gridrow: "5/6",
  },
];
