import styled from "styled-components";
import { motion } from "framer-motion";

export const FooterContainer = styled.div`
  position: relative;
  overflow: hidden;
  bottom: 0;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 100px;
  background-color: var(--text-color);
  color: var(--background-color);

  .up-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
  }
  .scroll-top {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    transition: all 0.5s ease-in-out;
    opacity: 0;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      top: 0;
      left: 50%;
    }

    P {
      position: absolute;
      font-size: 0.8rem;
      color: var(--second-text-color);
      top: -20px;
    }
  }

  .scroll-top.active {
    top: -100px;
    opacity: 1;
    @media screen and (max-width: 768px) {
      top: -60px;
    }
  }
`;

export const Copyright = styled.div`
  padding: 1em 0 1em 0;
  p {
    color: var(--second-text-color);
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
  }
`;
export const HeadingSplit = styled.div`
  text-transform: uppercase;
  font-size: calc(3vw + 1em);
  line-height: 0.92;
  padding-top: 1em;
  h2 {
    display: block;
    text-align: start;
    position: relative;
  }

  h2:nth-of-type(2) {
    padding-bottom: 0.2em;
    width: 90%;
    /* border-bottom: 1px solid var(--second-text-color); */
  }
  @media screen and (max-width: 768px) {
    font-size: 8vw;
  }
`;
export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  font-family: Helvetica;
  overflow: hidden;
  padding-top: 2em;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export const FlexLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  padding: 2em;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const FooterLink = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 1em;
  padding-bottom: 1em;

  svg {
    margin-top: 5px;
    fill: var(--background-color);
    margin-right: 1em;
  }

  p {
    border-radius: 15px;
    padding: 0.5em;
    background-color: var(--background-color);
    color: var(--text-color);
    font-size: calc(1vw + 0.75em);
    position: relative;
    ::after {
      content: "";
      position: absolute;
      left: -6px;
      top: 3px;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid var(--background-color);
    }
  }
`;

export const ContactInfo = styled.div`
  padding-top: 0em;
  padding-bottom: 2em;

  h4 {
    text-transform: uppercase;
    font-weight: 300;
    font-size: calc(1vw + 0.8em);
    line-height: 2em;
  }

  button {
    font-size: 1.2rem;
    color: var(--text-color);
    background-color: var(--background-color);
    align-self: flex-start;
    padding: 0.5em;
    border-radius: 25px;
    outline: none;
    border: none;
  }
  a {
    text-decoration: none;
    color: var(--text-color);
  }
`;

export const FlexRight = styled.div`
  display: flex;
  width: 40%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: center;
    overflow: hidden;

    @media screen and (max-width: 768px) {
    }
  }

  .clock {
    width: 100%;
  }
  .clock span {
    list-style-type: none;
    text-align: left;
    font-size: 1rem;
  }
  .clock h3 {
    font-size: 1.4rem;
    text-align: left;
  }
  .location-container {
    position: absolute;
    bottom: 100px;
    left: 0;
    padding: 2em;
    padding-bottom: 3em;
    color: #fff;
    width: 100%;
  }
  .location-container h3 {
    overflow: hidden;
    font-size: calc(1vw + 2rem);
    padding-top: 0.5rem;
    text-transform: uppercase;
    color: var(--secondary-text-color);
  }
  .location-container span {
    text-transform: uppercase;
    /* line-height: .8rem; */
    margin-top: -1em;
    width: 50%;
    font-weight: 300;
    font-size: calc(1vw + 0.7em);
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
