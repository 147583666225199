export const imgGal = [
  {
    id: "1",
    src: "./images/sp/sp-ipadTwo.jpg",
    gridcolumn: "1/8",
    gridrow: "1/2",
  },
  {
    id: "2",
    src: "./images/sp/sp-mobileFlatlay.jpg",
    gridcolumn: "1/8",
    gridrow: "2/3",
  },
  {
    id: "3",
    src: "./images/sp/sp-mobileTwoFlat1.jpg",
    gridcolumn: "1/8",
    gridrow: "3/4",
  },
  {
    id: "4",
    src: "./images/sp/sp-mobileTwoFlat2.jpg",
    gridcolumn: "1/8",
    gridrow: "4/5",
  },
  {
    id: "5",
    src: "../images/sp/sp-mobileTwoFlat3.jpg",
    gridcolumn: "1/8",
    gridrow: "5/6",
  },
  {
    id: "6",
    src: "../images/sp/sp-animation.gif",
    gridcolumn: "1/8",
    gridrow: "6/7",
  },
  {
    id: "7",
    src: "../images/sp/sp-lockupbw.png",
    gridcolumn: "1/4",
    gridrow: "7/8",
  },
  {
    id: "8",
    src: "../images/sp/sp-lockup.png",
    gridcolumn: "5/8",
    gridrow: "7/8",
  },
];
