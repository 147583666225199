import "./App.css";
import React from "react";
import GlobalStyle from "./globalStyles";
import HomePage from "./pages/HomePage";
import Theories from "./pages/Theories";
import Buffalo from "./pages/Buffalo";
import Sandys from "./pages/Sandys";
import Calendar from "./pages/Calendar";
import ScrollToTop from "./ScrollToTop";
import Info from "./pages/Info";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <GlobalStyle />
        <Navbar />

        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/info" exact element={<Info />} />
          <Route path="/theories" exact element={<Theories />} />
          <Route path="/buffalo" exact element={<Buffalo />} />
          <Route path="/sandys" exact element={<Sandys />} />
          <Route path="/calendar" exact element={<Calendar />} />
        </Routes>
        <Footer />
      </ScrollToTop>
    </Router>
  );
}

export default App;
