import React from "react";
import { NavLink } from "react-router-dom";
import { Container } from "../../globalStyles";
import DarkMode from "../DarkMode/DarkMode";
import {
  NavBar,
  ButtonContainer,
  NavLinkContainer,
} from "../Navbar/NavbarStyles";
import Email from "../EmailButton/Email";

const Navbar = () => {
  return (
    <Container>
      <NavBar>
        <NavLinkContainer>
          <NavLink to="/info">
            <li className="main-nav">Info</li>
          </NavLink>
          <NavLink to="/" exact>
            <li className="main-nav" activeClassName="active">
              Work
            </li>
          </NavLink>
        </NavLinkContainer>

        <ButtonContainer>
          <Email />
          <DarkMode />
        </ButtonContainer>
      </NavBar>
    </Container>
  );
};

export default Navbar;
