import React from 'react'
import { motion } from "framer-motion";
import { CButtonContainer, CircleContainer } from './CircleButtonStyles';

const ToTopButton = () => {
   window.scrollTo({top: 0, behavior: 'smooth'}); 
   ;
}

const circleMotion = {
    rest: {
      pathLength: 1,
      opacity: 1,
    },
    hover: {
        pathLength: [0,1,1],
        opacity: 1,
        transition: {
          duration: 1,
          ease: "easeIn"
      },
    
    },
    inview: {
        
            pathLength: [0,1,1],
            opacity: 1,
            transition: {
              duration: 1,
              ease: "easeIn",
              delay: .5,
          },
    }
  };
  
  const arrowMotion = {
    rest: {
        y: 0,
        opacity: 1,
    },
    hover: {
        y: 50,
        opacity:1,
        transition: {duration: .5, },
      },

    inview: {
       
            y: 50,
            opacity:1,
            transition: {duration: .5, delay: .5, },
          
    }
    
  };

const arrowMotion2 = {
    rest: {
        y: -50,
        opacity: 1,
        
    },
    hover:{
        y:0,
        opacity: 1,
        transition: {duration: .5, delay: .5},

    },
    inview: {
        y:0,
        opacity: 1,
        transition: {duration: .5, delay: 1},

    }
};


    const CircleButton = () => {
      
    return (
        <div onClick={ToTopButton}  >
            <CButtonContainer whileInView="inview" initial="rest" whileHover="hover" animate="rest" >
      <CircleContainer>
      <svg 
x="0px" y="0px"
width="100px" 
height="100px" viewBox="0 0 100 100">

<motion.path variants={circleMotion}

fill="none"
stroke="#000000" 
stroke-miterlimit="10" 
d="M87.767,49.381c0,20.858-16.909,37.768-37.767,37.768
c-20.858,0-37.767-16.909-37.767-37.768c0-20.858,16.908-37.767,37.767-37.767C70.857,11.614,87.767,28.523,87.767,49.381z"
/>

<defs>
<clipPath id="clip">
      <rect x="0" y="20" width="100" height="60" />
    </clipPath>
</defs>
  <g clipPath="url(#clip)">


<motion.path 
variants={arrowMotion}
fill="none" 
stroke="#000000" 
stroke-width="3" 
stroke-linecap="round" 
stroke-linejoin="round" 
stroke-miterlimit="10" 
d="M61.656,55.967L49.747,68L37.973,56.309L49.5,68V30"
/>

<motion.path 
variants={arrowMotion2}
fill="none" 
stroke="#000000" 
stroke-width="3" 
stroke-linecap="round" 
stroke-linejoin="round" 
stroke-miterlimit="10" 
d="M61.656,55.967L49.747,68L37.973,56.309L49.5,68V30"
/>
</g>

        </svg>
      </CircleContainer>
      
      <h1 className="btn-text">( back to top )</h1> 
    </CButtonContainer>
            
        </div>
    )
}


export default CircleButton