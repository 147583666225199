import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Container } from "../../globalStyles";
import {
  ContentSec,
  ContentRow,
  TextWrapper,
  TopLine,
  Heading,
  ContentButton,
  Subtitle,
  ImgWrapper,
  Img,
  ContentColumn,
  CardImageColumn,
} from "./CardStyles";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";

const Card = ({
  primary,
  topLine,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  start,
  bottomImg,
  backgroundColor,
  linkTo,
  inverse,
  reverse,
  bigImage,
  id,
}) => {
  const initial = { opacity: 0, y: 30 };
  const transition = { delay: 0.3, duration: 0.6 };
  const animation = useAnimation();

  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
      });
    }
  }, [inView, animation]);

  return (
    <ContentSec ref={ref}>
      <Container>
        <ContentRow reverse={reverse}>
          <ContentColumn>
            <TextWrapper>
              <TopLine
                initial={initial}
                transition={{ delay: 0.3, duration: 0.6 }}
                animate={animation}
              >
                {topLine.text}
              </TopLine>
              <Heading
                initial={initial}
                transition={transition}
                animate={animation}
                inverse={inverse}
              >
                {headline}
              </Heading>
              <Subtitle
                initial={initial}
                transition={{ delay: 0.7, duration: 0.6 }}
                animate={animation}
                inverse={inverse}
              >
                {description}
              </Subtitle>
              <NavLink to={linkTo}>
                <ContentButton
                  initial={initial}
                  transition={{ delay: 1, duration: 0.6 }}
                  animate={animation}
                  primary={primary}
                  backgroundColor={backgroundColor}
                >
                  {buttonLabel}
                </ContentButton>
              </NavLink>
            </TextWrapper>
          </ContentColumn>
          <CardImageColumn
            initial={initial}
            transition={transition}
            animate={animation}
            bigImage={bigImage}
          >
            <NavLink to={linkTo}>
              <ImgWrapper>
                <Img
                  src={img}
                  alt={alt}
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.5 }}
                />
              </ImgWrapper>
            </NavLink>
          </CardImageColumn>
        </ContentRow>
      </Container>
    </ContentSec>
  );
};

export default Card;
