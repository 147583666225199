export const vid = {
  img: "./images/bflo/bfloMobVid.mp4",
  gridcolumn: "1/4",
  gridrow: "1/2",
};

export const vidLG = {
  img: "./images/bflo/bfloLGVid.mp4",
  gridcolumn: "2/7",
  gridrow: "5/6",
};
export const imgGal = [
  {
    id: "1",
    src: "./images/bflo/bfloWebMock.jpg",
    gridcolumn: "4/8",
    gridrow: "1/2",
  },
  {
    id: "2",
    src: "./images/bflo/bfloShirt.jpg",
    gridcolumn: "1/8",
    gridrow: "2/3",
  },
  {
    id: "3",
    src: "./images/bflo/bfloMug1.jpg",
    gridcolumn: "1/8",
    gridrow: "3/4",
  },
  {
    id: "4",
    src: "./images/bflo/bfloIpadMock.jpg",
    gridcolumn: "1/8",
    gridrow: "4/5",
  },
];
