export const skills = [
  {
    title: "Development",
    list: [
      "Web Dev",
      "Responsive Layouts",
      "HTML, CSS and JavaScript",
      "ReactJS",
      "PHP",
      "Wordpress",
      "WooCommerce",
      "Wire-Framming",
      "Prototyping",
    ],
  },

  {
    title: "Design",
    list: [
      "Web Design",
      "UI / UX",
      "Figma",
      "Brand Identity",
      "Logo Design",
      "Typeface",
      "Illustration",
      "Product Design",
      "Design for Print",
      "Videography / Editing",
      "Motion Design / Animation",
    ],
  },
];

export const bioInfo = {
  img: "./images/info/sptest.jpg",
};
