import React, { useState } from "react";

function Clock() {
  let time = new Date().toLocaleTimeString("en-US", {
    timeZone: "America/New_York",
    hour: "2-digit",
    minute: "2-digit",
  });
  const [currentTime, setCurrentTime] = useState(time);

  const updateTime = () => {
    let time = new Date().toLocaleTimeString("en-US", {
      timeZone: "America/New_York",
      hour: "2-digit",
      minute: "2-digit",
    });
    setCurrentTime(time);
  };

  setInterval(updateTime, 1000);

  return (
    <div className="clock">
      <span>
        Current <br /> Time ET
      </span>
      <h3>{currentTime}</h3>
    </div>
  );
}

export default Clock;
