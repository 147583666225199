import styled from "styled-components";
import { motion } from "framer-motion";

export const ContentSec = styled.div`
  padding: 100px 0;
  /* background: var(--background-color); */
  @media screen and (max-width: 768px) {
    padding: 50px 0 0;
  }
`;

export const ContentRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  /* flex-wrap: wrap; */
  align-items: center;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  justify-content: space-around;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: start;
  }
`;

export const ContentColumn = styled(motion.div)`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  z-index: 10;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    max-width: 100% !important;
    flex-basis: 100%;
    /* justify-content: center;
		align-items: center; */
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-left: 30px;
  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
    padding-left: 5px;
    > h1,
    p {
      /* text-align: center; */
    }
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }
  > img {
    width: 300px;
    margin-left: -3px;
  }
`;

export const ContentButton = styled(motion.button)`
  position: relative;
  font-family: "Helvetica Neue", "Helvetica", sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 18px;
  letter-spacing: 1.54px;
  text-transform: uppercase;
  border: none;
  padding-bottom: 2px;
  cursor: pointer;
  background-color: transparent;
  color: var(--text-color);
  &:after {
    content: "";
    position: absolute;
    width: calc(100%);
    height: 0.1rem;
    left: 0;
    bottom: -3px;
    background-color: var(--text-color);
    /* color: var(--text-color); */
    transform: scale(0, 1);
    transform-origin: left;
    transition: transform 0.4s ease;
  }
  &:hover:after {
    transform: scale(1, 1);
  }
`;

export const ImgWrapper = styled(motion.div)`
  display: flex;
  justify-content: ${({ imgStart }) => (imgStart ? "flex-start" : "flex-end")};
  max-height: 600px;
  justify-content: center;
  position: relative;
  background: #fff;
  overflow: hidden;
`;

export const TopLine = styled(motion.div)`
  font-size: 0.9rem;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 1.3rem;
  color: var(--second-text-color);
`;

export const Img = styled(motion.img)`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  object-fit: cover;
  /* height: 300px;
	max-height: 700px; */
  z-index: 1;
`;

export const Heading = styled(motion.h2)`
  margin-bottom: 24px;
  font-size: 1.5rem;
  line-height: 1.1;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--text-color);
  @media screen and (max-width: 768px) {
    /* text-align: center; */
  }
`;

export const Subtitle = styled(motion.p)`
  max-width: 440px;
  margin-bottom: 35px;
  line-height: 24px;
  color: var(--text-color);
`;

export const CardImageColumn = styled(motion.div)`
  width: 70%;
  margin-bottom: 30px;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
