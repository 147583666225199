import React, { useState, useEffect, useRef } from "react";
import {
  ContactButton,
  DropDownContainer,
  MailIcon,
  DropDown,
} from "../EmailButton/EmailStyles";
import IconMail from "./IconMail";
import CopyClipboard from "./CopyClipboard";

let useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });
  return domNode;
};

const EmailButton = ({ email }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contactButton = {
    open: {
      x: "-45px",
      transition: { ease: [0.6, 0.01, -0.05, 0.9] },
      scale: 1,
      opacity: 1,
      // transition:{
      // opacity: {delay: 0.1 }}
    },
    close: {
      scale: 0.5,
      x: "50px",
      transition: { ease: [0.6, 0.01, -0.05, 0.9] },
      opacity: 0,
    },
  };
  let domNode = useClickOutside(() => {
    setIsOpen(false);
  });

  const Mailto = ({ email, subject = "", body = "", children }) => {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };

  return (
    <DropDownContainer ref={domNode}>
      <ContactButton
        onClick={() => setIsOpen((state) => !state)}
        whileTap={{ scale: 0.9 }}
        className="contact"
      >
        <MailIcon>
          <IconMail />
        </MailIcon>
      </ContactButton>

      <DropDown
        initial={false}
        variants={contactButton}
        animate={isOpen ? "open" : "close"}
      >
        <Mailto email="mmmunrooo@gmail.com" subject="Hello from portfolio site">
          mmmunrooo@gmail.com
        </Mailto>
      </DropDown>
    </DropDownContainer>
  );
};

export default EmailButton;
