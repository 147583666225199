import React from "react";
import Card from "../components/Card/Card";
import Hero from "../components/Hero/Hero";
import { cardOne, cardTwo, cardThree, cardFour } from "../data/CardData";
const HomePage = () => {
  return (
    <>
      <Hero />
      <Card {...cardOne} />
      <Card {...cardTwo} />
      <Card {...cardThree} />
      <Card {...cardFour} />
    </>
  );
};

export default HomePage;
