import styled from "styled-components";

export const NavBar = styled.nav`
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3em;
  padding-top: 3em;
`;

export const NavLinkContainer = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.9rem;
  @media screen and (max-width: 768px) {
    font-size: 0.7rem;
  }

  & li {
    color: var(--text-color);
    display: flex;
    justify-content: start;
    align-items: center;
    border: none;
    background: transparent;
    text-decoration: none;
    margin: 0.5em;
    line-height: 2rem;
  }

  & a:visited,
  a:link {
    color: var(--text-color);
    text-decoration: none;
  }

  .main-nav {
    position: relative;
    color: var(--text-color);
  }
  .main-nav::after {
    content: "";
    position: absolute;
    width: calc(100%);
    height: 0.175rem;
    left: 0;
    bottom: -4px;
    background: var(--text-color);
    transform: scale(0, 1);
    transition: transform 0.4s ease;
  }
  .main-nav:hover::after {
    transform: scale(1, 1);
  }
  .active li::after {
    content: "";
    position: absolute;
    width: calc(100%);
    height: 0.175rem;
    left: 0;
    bottom: -4px;
    background: var(--text-color);
    transform: scale(1, 1);
  }
  .contact {
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1em;
`;
